import React from 'react';
import { Link } from 'react-router-dom';
import "../../assets/style/common/footer.scss";
import { BsDiscord } from "react-icons/bs";
import { AiFillLinkedin, AiFillTwitterCircle } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa"
const Footer = () => {
  return (
    <>
      <footer className='app_footer'>
        {/* <Container fluid> */}
        <div className='footer_text_wrap'>
          <ul>
            <li><Link to="https://www.deperp.com/dela">Dela</Link></li>
            <li><Link to="https://www.deperp.com/about#Delayer">Docs</Link></li>
            <li><Link to="https://twitter.com/DeLaChain">X.com</Link></li>
            <li><Link to="https://sepolia-delascan.deperp.com/">Dela Explorer</Link></li>
          </ul>
        </div>
        {/* </Container> */}
      </footer>
    </>
  )
}

export default Footer